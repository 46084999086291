<template>
  <div class="enterprise">
    <div class="entPri">
      <div class="imgWarp">
        <el-image
          fit="scale-down"
          :src="detail.logo ? detail.logo : defaultLogo"
        >
        </el-image>
      </div>
      <div class="titleWarp">
        <p class="company_name" @click="getMorePosition(detail.company_id)">
          {{ detail.company_name_zh || detail.company_name_en | textFormat }}
        </p>
        <p>
          <span>{{detail.company_country | countryFormat(LOCALE)}}-{{detail.company_region_zh || detail.company_region_en | textFormat}}</span>
        </p>
      </div>
    </div>
    <!-- 招聘信息 -->
    <div class="informationWarp">
      <div class="information">
        <div>
          <div  v-for="(item,i) in detail.job_list.slice(0,3)" :key="i" class="jobList">
            <div class="font1">{{item.name}}</div>
            <div class="font2">{{filterCity(item.city,"zh")}}</div>
            <!-- <button class="btn1">投递</button> -->
          </div>
          
        </div>

      </div>
    </div>
    <!-- 底部视频 -->
    <div class="videoAppointments">
      <span
        class="spa1"
        @click="getMorePosition(detail.company_id, detail.booth_id)"
        >{{ detail.job_list.length }}个职位</span
      >

    </div>
  </div>
</template>

<script>
import { getCityNameById } from "~/baseUtils/AddressConfig";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    indexs: {
      type: Number | String,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      resData: [],
      // cityList:[]
    };
  },
  created() {
    this.filterCity = getCityNameById;
  },
  mounted() {
    // this.getPositionData();
      // console.log(this.detail)
    // this.getCompanyInfo()
  },
  methods: {
    //接口
    // async getCompanyInfo(){
    //   let params = {
    //     source:this.PJSource
    //   }
    //   let data = await this.$store.dispatch('API_company/getCreditCityList',params)
    //   // console.log(data)
    //   if(data.success){
    //     this.cityList = data.data
    //   }

    // },
    //获取职位信息
    getPositionData() {
      let res = this.detail.job_list;
      this.resData = res;
       console.log(this.resData)
    },
    //获取更多职位
    getMorePosition(company_id) {
      this.$router.push({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id,
          }),
        },
      });
    },
    //跳转职位详情
    goToDetail(item){
      this.$router.push({
          path: "/positionDetail",
          query: {
            parameter: this._encode({
              id: item.position_id,
            }),
          },
        });
    }
  },
};
</script>

<style scoped lang="less">
.enterprise {
  width: 420px;
  height: 314px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9e9e9;
  margin: 5px 0;
  //title
  .entPri {
    width: 100%;
    height: 90px;
    display: flex;
    padding: 5px 8px;
    border-bottom: 1px solid #e9e9e9;
    border-radius: 0px 0px 2px 2px;
    position: relative;
    .imgWarp {
      width: 70px;
      height: 70px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // cursor: pointer;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .titleWarp {
      width: 350px;
      height: 100%;
      margin-left: 10px;
      cursor: pointer;
      p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin: 0 5px;
      }
    }
    // .titles{
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    // }
    // .titles:hover{
    // text-overflow:inherit; 
    // overflow: visible; 
    // white-space: pre-line;     /*合并空白符序列，但是保留换行符。*/
    // }
  }
  //招聘信息
  .informationWarp {
    padding: 0 10px;
    width: 100%;
    height: 100px;
    .information {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 10px 0;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-top: 15px;
        display: flex;

        span {
          margin-left: 10px;
          height: 20px;
          line-height: 20px;
        }
        .sp1{
          cursor: pointer;
        }
        .sp2 {
          margin-left: 30px;
          color: #999999;
        }
      }
      //投递按钮
      /*  .btn {
        height: 28px;
        width: 80px;
        background: #1890ff;
        border-radius: 15px;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
      } */
    }
  }
  //底部预约
  .videoAppointments {
    width: 100%;
    height: 65px;
    background: #f8f9fc;
    margin-top: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    background: #ffffff;
  }
  .spa1 {
    width: 133px;
    height: 32px;
    cursor: pointer;
    line-height: 32px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    color: #666666;
    margin: 0 20px;
  }
  // .spa2 {
  //   width: 96px;
  //   height: 32px;
  //   line-height: 32px;
  //   color: #1890ff;
  //   border: 1px solid #1890ff;
  //    margin: 0 20px;
  // }
}
.hot {
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
}
.hot img {
  width: 100%;
  height: 100%;
}
.jobList{
  display: flex;
  margin-bottom: 12px;
  // justify-content: space-between;
}
.font1{
  width: 200px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
line-height: 20px;
margin:6px 6px 0 0;
}
.font2{
  
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
line-height: 20px;
// margin:6px 220px 0 0;
margin: 6px 0 0 20px;

}
.btn1{
  width: 80px;
height: 30px;
background: #1890FF;
border-radius: 15px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 20px;
border: 0px;
}
.btn2{
  width: 96px;
height: 32px;
background: #FFFFFF;
border-radius: 4px;
border: 1px solid #1890FF;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #1890FF;
line-height: 31px;
margin: 0 20px;
}
</style>
