<template>
  <div class="filterSearchComponents content_area">
    <div class="searchBox">
      <el-input
        :placeholder="inputPlaceholder"
        v-model="keyWord"
        class="input-with-select"
      >
        <el-select
          v-model="select"
          filterable
          slot="prepend"
          placeholder="期望工作地点"
          clearable
        >
          <el-option
            v-for="(item, index) in cityList"
            :label="item.name_zh"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
        ></el-button>
      </el-input>
    </div>
    <div class="hotCity">
      热门城市：
      <span v-for="item in hotCity" :key="item.id" @click="select = item.id">{{
        item.label
      }}</span>
    </div>
    <div class="filters">
      <!-- <span
        @click="clearFilter('levelRequire')"
        v-if="filters.levelRequire"
        class="cursor"
        ><i class="el-icon-close"></i
      ></span>
      <el-dropdown
        trigger="click"
        placement="bottom-start"
        @command="levelRequireClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.levelRequire">{{
            filters.levelRequire | levelRequireFormat("zh")
          }}</span>
          <span v-else>学历要求</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in levelRequire"
            :key="item.value"
            :command="item"
            >{{ item.label_zh }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <span @click="clearFilter('salary')" v-if="filters.salary" class="cursor"
        ><i class="el-icon-close"></i
      ></span>
      <el-dropdown
        trigger="click"
        placement="bottom-start"
        @command="salaryClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.salary">{{ filters.salary }}</span>
          <span v-else>薪资待遇</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in salaryList"
            :key="item.value"
            :command="item"
            >{{ item.value }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <span
        @click="clearFilter('staffsize')"
        v-if="filters.staffsize"
        class="cursor"
        ><i class="el-icon-close"></i
      ></span>
      <el-dropdown
        trigger="click"
        placement="bottom-start"
        @command="staffsizeListClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.staffsize">{{
            filters.staffsize | staffSizeFormat("zh")
          }}</span>
          <span v-else>公司规模</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in staffsizeList"
            :key="item.value"
            :command="item"
            >{{ item.label_zh }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
      <!-- <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          预约时间段<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>黄金糕</el-dropdown-item>
          <el-dropdown-item>狮子头</el-dropdown-item>
          <el-dropdown-item>螺蛳粉</el-dropdown-item>
          <el-dropdown-item>双皮奶</el-dropdown-item>
          <el-dropdown-item>蚵仔煎</el-dropdown-item>
        </el-dropdown-menu>
        <div class="fr clearFilter" @click="clearAll">清空筛选条件</div>
      </el-dropdown> -->
      
    </div>
  </div>
</template>

<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
import { staffsizeList, levelRequire } from "~scr/utils/enumValue";

export default {
  props: {
    inputPlaceholder: {
      type: String,
      default: '请输入公司'
    }
  },
  data() {
    return {
      select: "",
      keyWord: "",
      cityList: [],
      staffsizeList,
      levelRequire,
      filters: {
        levelRequire: "",
        staffsize: "",
        salary: "",
      },
      hotCity: [
        { id: 206, label: "广州" },
        { id: 223, label: "中山" },
        { id: 208, label: "深圳" },
        { id: 122, label: "厦门" },
        { id: 121, label: "福州" },
        { id: 80, label: "上海" },
        { id: 95, label: "宁波" },
        { id: 87, label: "连云港" },
        { id: 94, label: "杭州" },
        { id: 192, label: "长沙" },
        { id: 176, label: "武汉" },
        { id: 260, label: "重庆" },
        { id: 142, label: "青岛" },
        { id: 158, label: "郑州" },
        { id: 4, label: "天津" },
        { id: 40, label: "大连" },
      ],
      salaryList: [
        {
          max: 3,
          min: 0,
          value: "3K以下",
        },
        {
          max: 5,
          min: 3,
          value: "3-5K",
        },
        {
          max: 10,
          min: 5,
          value: "5-10K",
        },
        {
          max: 15,
          min: 10,
          value: "10-15K",
        },
        {
          max: 20,
          min: 15,
          value: "15-20K",
        },
        {
          max: 100,
          min: 20,
          value: "20K以上",
        },
      ],
    };
  },
  mounted() {
    this.cityList = getCityListByCountryId(1, "zh");
  },
  methods: {
    initParams(params) {
      //params:city城市,content输入内容，levelRequire学历信息,staffSize企业规模，
      this.select = params.city;
      this.keyWord = params.content;
      this.filters.levelRequire = params.levelRequire;
      this.filters.staffsize = params.staffsize;
    },
    salaryClick(item) {
      this.filters.salary = item.value;
      this.handleSearch();
    },
    levelRequireClick(item) {
      this.filters.levelRequire = item.value;
      this.handleSearch();
    },
    staffsizeListClick(item) {
      this.filters.staffsize = item.value;
      this.handleSearch();
    },
    clearFilter(key) {
      this.filters[key] = "";
      this.handleSearch();
    },
    clearAll() {
      this.filters.levelRequire = "";
      this.filters.staffsize = "";
      this.filters.salary = ""
      this.handleSearch();
    },
    search() {
      this.filters.levelRequire = "";
      this.filters.staffsize = "";
      this.handleSearch();
    },
    handleSearch() {
      let params = {
        city: this.select,
        content: this.keyWord,
        levelRequire: this.filters.levelRequire, //学历信息
        staffSize: this.filters.staffsize, //企业规模
      };
      let salary = this.salaryList.find((item) => {
        return item.value == this.filters.salary;
      });
      if (salary) {
        params.salary = {
          //薪资待遇
          min: salary.min,
          max: salary.max,
        };
      }
      this.$emit("handleSearch", params);
    },
  }
};
</script>

<style scoped lang="less">
.filterSearchComponents {
  width: 1300px;
  height: 178px;
  background: #fff;
  padding: 24px 30px;
  .searchBox {
    width: 885px;
    margin-bottom: 24px;
    /deep/ .el-input-group__prepend {
      border-radius: 0px;
      border: 1px solid #1890ff;
      border-right: 0 none;
    }
    /deep/ .el-input__inner {
      border-top-color: #1890ff;
      border-bottom-color: #1890ff;
      &:hover {
        border-left-color: #dcdfe6;
      }
      &:focus {
        border-left-color: #dcdfe6;
      }
    }
    /deep/ .input-with-select .el-select .el-input {
      width: 135px;
    }
    /deep/ .el-select {
      width: 135px !important;
    }
    /deep/ .el-button {
      background: #1890ff;
      color: #fff;
      border-radius: 0px;
      width: 90px;
      font-size: 26px;
      padding: 6px 20px 7px;
      position: relative;
      top: 2px;
    }
  }
  .hotCity {
    color: #666;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 14px;
    margin-bottom: 16px;
    span {
      margin-right: 24px;
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
  }
  .filters {
    .el-dropdown-link {
      cursor: pointer;
    }
    .el-dropdown {
      margin-right: 80px;
      width: 120px;
    }
    .clearFilter {
      color: #999999;
      cursor: pointer;
    }
  }
}
</style>
