<template>
  <div class="company">
    <!-- banner图 -->
    <div class="bannerWarp">
      <div class="baImg1">
        <p>在线招聘企业</p>
        <span>{{ this.conpanyCount.company_count }}家</span>
      </div>
      <div class="baImg2">
        <p>招聘职位</p>
        <span>{{ this.conpanyCount.job_position_count }}个</span>
      </div>
      <div class="baImg3">
        <p>参与人数</p>
        <span>{{ this.conpanyCount.participate_count }}人</span>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="contentWarp">
      <!-- 搜索 -->
      <FilterSearch @handleSearch="handleSearch"></FilterSearch>
      <!-- 公司信息 -->
      <div
        class="mainWarp"
        v-loading="loading"
        style="position: relative; min-height: 330px"
      >
        <EnterpriseInfo
          v-for="(item, index) in companyList"
          :key="index"
          :detail="item"
          :indexs="index"
        ></EnterpriseInfo>
        <div class="vacancy" v-if="this.count == 0">
          <noDataImg></noDataImg>
        </div>
      </div>
    </div>
    <div class="pagination content_area" v-show="hiddn">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :total="count"
        :page-size="pages"
        :current-page.sync="pageNow"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import EnterpriseInfo from "~hbs/components/common/enterpriseInfo";
import FilterSearch from "~hbs/components/common/filterSearch_copy";
import noDataImg from "~/baseComponents/noDataImg";
export default {
  metaInfo: {
    title: "企业列表",
  },
  components: {
    EnterpriseInfo,
    FilterSearch,
    noDataImg,
  },
  data() {
    return {
      companyList: [],
      conpanyCount: [],
      hiddn: false,
      count: 0, //默认总条数
      pages: 12, //每页显示条数
      pageNow: 1, //默认第一页
      level_require: "", //学历要求
      company_name: "", // 公司名
      salary_highest: "", //最高薪资
      salary_lowest: "", //最低薪资
      city: "", //城市
      scale: "", //规模
      loading: false,
      companyName: "",
    };
  },
  watch: {
    pageNow() {
      this.getCompanyList();
    },
  },
  methods: {
    async getCompanyList() {
      this.loading = true;
      let params = {};
      params.limit = 12;
      params.nopage = 0;
      params.start = this.pageNow - 1;
      // params.level_require = this.level_require;
      // params.scale = this.scale;
      params.city = this.city;
      params.company_name = this.companyName;
      params.source = this.PJSource;
      let result = await this.$store.dispatch(
        "API_company/getEntrpriseList",
        params
      );
      this.loading = false;
      if (result.success) {
        this.count = result.count;
        if (this.count >= this.pages) {
          this.hiddn = true;
        }
        console.log(result);
        this.companyList = result.data;
      }
    },
    async getCompanyCount() {
      let res = await this.$store.dispatch("API_index/getTotalMsg", {
        source: this.PJSource,
      });
      // console.log(res)
      if (res.success) {
        this.conpanyCount = res.data;
      }
    },
    handleSearch(params) {
      this.level_require = params.levelRequire;
      this.scale = params.staffSize;
      this.city = params.city;
      this.companyName = params.content;
      this.getCompanyList();
    },
  },
  mounted() {
    this.getCompanyList();
    this.getCompanyCount();
  },
};
</script>

<style lang="less" scoped>
html,
body {
  margin: 0;
  padding: 0;
  background: #f2f4f6;
}
.company {
  min-height: calc(100vh - 100px);
  background: #f2f4f6;
  position: relative;
}
// banner图
.bannerWarp {
  width: 100%;
  height: 266px;
  background: url("../../assets/img/companyBanner.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerWarp div {
  width: 266px;
  height: 176px;
  margin: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bannerWarp div p {
  font-size: 16px;
  color: #666666;
}
.bannerWarp div span {
  font-size: 36px;

  font-weight: 800;
  color: #1890ff;
}
.baImg1 {
  background: url("../../assets/img/company1.png") no-repeat;
}
.baImg2 {
  background: url("../../assets/img/company2.png") no-repeat;
}
.baImg3 {
  background: url("../../assets/img/company3.png") no-repeat;
}
.demo {
  width: 420px;
  height: 314px;
  background: chartreuse;
  margin: 5px 0;
}
//内容区域
.contentWarp {
  width: 1300px;
  min-height: 200px;
  margin: 15px auto;
  margin-bottom: 0;
  //企业信息
  .mainWarp {
    width: 100%;
    // height: 972px;
    margin: 15px 0;
    margin-bottom: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mainWarp:after {
    content: "";
    width: 33%;
  }
}
.pagination {
  position: relative;
  bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.vacancy {
  width: 100px;
  height: 200px;
  //   font-size: 16px;
  //   text-align: center;
  //   line-height: 200px;
  //   color: #666666;
  margin: 50px auto;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   bottom: 50%;
  //   right: 50%;
  //   background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata1.png");
}
</style>
